<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import simplebar from "simplebar-vue";

export default {
	page: {
		title: "Workshop Items",
		meta: [{name: "description", content: appConfig.description}]
	},
	components: {
		/* eslint-disable vue/no-unused-components */
		simplebar,
		Layout,
		ItemList: () => import('@/components/lazy/workshop/ItemList'),
	},
	data() {
		return {};
	},
	validations: {},
	methods: {}
};
</script>

<template>
	<Layout>
		<ItemList/>
	</Layout>
</template>